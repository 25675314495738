import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "./environments/environment";
import {
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
} from "@angular/core";
import { AppComponent } from "./app/app.component";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { ImageCropperModule } from "ngx-image-cropper";
import { NzFormModule } from "ng-zorro-antd/form";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  withInterceptorsFromDi,
  provideHttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AppRoutes } from "./app/pages/app-routes";
import {
  withHashLocation,
  withInMemoryScrolling,
  withRouterConfig,
  provideRouter,
} from "@angular/router";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { NzImageService } from "ng-zorro-antd/image";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzMessageService } from "ng-zorro-antd/message";
import { ngZorroConfig } from "./app/core/ngZorroConfig";
import { NZ_CONFIG } from "ng-zorro-antd/core/config";
import { NZ_I18N, fr_FR } from "ng-zorro-antd/i18n";
import { ErrorInterceptor } from "./app/core/helpers/error.interceptor";
import { JwtInterceptor } from "./app/core/helpers/jwt.interceptor";
import { registerLocaleData } from "@angular/common";
import fr from "@angular/common/locales/fr";

if (environment.production) {
  enableProdMode();
}

registerLocaleData(fr);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      NzFormModule,
      ImageCropperModule,
      NzDividerModule,
      NzButtonModule,
      NzSpinModule
    ),
    { provide: DEFAULT_CURRENCY_CODE, useValue: "CFA" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: LOCALE_ID, useValue: "fr" },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    NzMessageService,
    NzNotificationService,
    NzModalService,
    NzDrawerService,
    NzImageService,
    provideRouter(
      AppRoutes,
      withInMemoryScrolling({ scrollPositionRestoration: "top" })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.error(err));
