import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { IResponse } from "../core/interfaces/IResponse";
import { UtilsService } from "./utils.service";
import { API_RESPONSE_STATUS } from "../core/enum";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _admin: BehaviorSubject<any | null>;

  constructor(private http: HttpClient, private utils: UtilsService) {
    this._admin = new BehaviorSubject(null);
  }

  handleLoginAdmin(data: any): Observable<IResponse<any>> {
    this.utils.RemoveToken();

    return this.http
      .post<IResponse<any>>(`${environment.baseUrl}admin/security/login`, data)
      .pipe(
        map((res: IResponse<any>) => {
          if (
            res.statusCode === API_RESPONSE_STATUS.SUCCESS &&
            res.data !== null
          ) {
            this.utils.SetToken(res.data.accessToken);
          }
          return res;
        })
      );
  }

  get admin$(): Observable<any> {
    return this._admin.asObservable();
  }

  public getProfile(): Observable<IResponse<any>> {
    return this.http
      .get<IResponse<any>>(`${environment.baseUrl}admin/security/admin-profile`)
      .pipe(
        tap((response) => {
          this._admin.next(response.data);
        })
      );
  }

  public requestChange(data: any): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(
      `${environment.baseUrl}customer/security/request-change-password`,
      data
    );
  }

  public sendConfirmationEmail(id: any): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(
      `${environment.baseUrl}admin/security/administrators/resend-confirm-email/${id}`
    );
  }

  public confirmAccess(data: any): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(
      `${environment.baseUrl}admin/security/confirm-email`,
      data
    );
  }
}
