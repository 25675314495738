import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";

import { inject } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

export const currentLoggedAdminResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AuthService).getProfile();
};
