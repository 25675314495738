import { Injectable } from "@angular/core";
import { mappingRoutes } from "../core/mappingRoutes";
import { LOCAL_STORAGE_KEY } from "../core/constantes";
import { Decrypt, Encrypt } from "../core/utils/encode-decode";
import { NzModalService } from "ng-zorro-antd/modal";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  /**************************
   * AUTHENTICATION SECTION *
   **************************/
  /**
   * Token value
   * @private
   */
  private _token: string | null = null;

  constructor(private router: Router, private nzModalService: NzModalService) {}

  get token(): string | null {
    return this._token;
  }

  set token(value: string | null) {
    this._token = value;
  }

  /**
   * Get Token
   * @constructor
   */
  public GetToken(): string | null {
    return Decrypt(localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN));
  }

  /**
   * Set Token
   * @param token
   * @constructor
   */
  public SetToken(token: string): void {
    if (this.GetToken() !== null && this.GetToken() !== token) {
      this.SetOldToken(this.GetToken()!);
    }
    localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, Encrypt(token));
  }

  SetOldToken(token: string) {
    localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, Encrypt(token));
  }

  /**
   * Set Token
   * @param token
   * @constructor
   */
  public SetUserToken(token: string): void {
    localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, Encrypt(token));
  }

  /**
   * Remove token
   * @constructor
   */
  public RemoveToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
  }

  /**
   * Remove token
   * @constructor
   */
  public RemoveAccountsToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
  }

  /**************
   * PAGE TITLE *
   **************/

  /**
   * Disconnect an user
   * @constructor
   */
  public Disconnect(): void {
    this.RemoveToken();
    this.RemoveAccountsToken();
    this.token = null;
    //    this.router.navigate([routeMap.auth.login]);
  }

  /**
   * Disconnect a user with confirmation
   * @constructor
   */
  public LogOut(): void {
    this.Disconnect();
    this.router.navigate([mappingRoutes.login]).then(() => {});
  }

  public hasToken() {
    return this.GetToken() !== null;
  }
}
