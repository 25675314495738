import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { UtilsService } from "../services/utils.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private utils: UtilsService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.utils.removeToken();

          location.reload();
        }
        const error = err.error?.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
