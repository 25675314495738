export enum ASSETS_IMAGES {
  EMPTY_DATA = "assets/images/svg/empty.svg",
}

export enum COLORS_NOTIFICATION {
  error = "#ef5764",
  warning = "#faad14",
  success = "#389e0d",
  info = "#33b1ff",
}

export enum STATUS_CODE {
  Ok = 7000,
}

export enum TYPE_PIECES_SUPPORT {
  CNI = "CNI",
  AUTRE = "AUTRE",
}

export enum USER_TYPE {
  planteurs = "planteur",
  artisans = "artisan",
}

//

export enum MESSAGE {
  DATA_NOT_FOUND = "Donnée non trouvée",
  CREATE_SUCCESS = "Enregistrement effectué avec succès.",
  CREATE_ERROR = "Échec de l'enregistrement.",
  EDIT_SUCCESS = "Modification éffectuer avec succès.",
  DELETE_SUCCESS = "Un élément supprimé.",
  DELETE_ERROR = "Échec de la suppression.",
  EDIT_ERROR = "Échec de la modification.",
  EMPTY_DATA = "Aucune donnée disponible.",
  SERVER_OUT = "Service non disponible pour l'instant !",
  REQUIRED_FIELD = "Ce champ est obligatoire.",
  INVALID_FIELD = "Ce champ est invalide.",
  EMAIL_EXISTE = "Erreur le mail existe déjà.",
  PASSWORD_INVALID = "Mot de passe invalide",
  USER_NOT_FOUND = "Aucun utilisateur trouvé",
  DATA_EXIST = "Cet élément existe déjà",
  ACCOUNT_NOT_ENABLED = "Ce compte n'est pas activé",
  INVALID_FORM = "Le formulaire est invalide",
  CONFIRM_DELETE = "Voulez-vous supprimer cet enregistrement ?",
  CONFIRM_ACTION = "Confirmez-vous cette action ? ",
  CONFIRM_DELETE_ALL = "Voulez-vous supprimer tout les enregistrements ?",
  VALIDATE_ACCOUNT_SUCCESS = "Votre compte a été validé ",
  UNAUTHORIZED_PERMISSION = "Permission non autorisée pour cette action. ",
  ERROR_SEND_OTP = "Code OTP incorrect. Veuillez réessayer",
  ERROR_SEND_OTP_OLD = "Échec d'envoi du code de confirmation, Vérifier vos informations",
  SUCCESS_SEND_OTP_MAIL = "Entrer le code reçu sur votre adresse mail enregistré",
  SUCCESS_SEND_OTP_PHONE = "Entrer le code reçu sur votre numéro de téléphone enregistré",
  NOT_AVAILABLE_FUNCTION = "Désolé, cette fonctionnalité est en cours de réalisation, celle-ci sera disponible dans les versions avenir.",
  NOT_AVAILABLE_CERTIFICAT_FOR_ADD_PROGRAM = "Certificat non valide. L'utilisateur ne peut donc pas participer au programme",
  DATA_NOT_AVAILABLE = "DATA_NOT_AVAILABLE",
}

export enum FORMATE_DATE_TYPE {
  ddMMyyyy = "dd-MM-yyyy",
  ddMMyyyyHHmmss = "dd-MM-yyyy HH:mm:ss",
  HHmmss = "HH:mm:ss",
  HHmm = "HH:mm",
  yyyyMMdd = "yyyy-MM-dd",
  yyyyMMddHHmmss = "yyyy-MM-dd HH:mm:ss",
}

export enum GENDER_USER {
  Femme = "F",
  Homme = "M",
}

export enum CIVILITY {
  Monsieur = "Monsieur",
  Madame = "Madame",
  Mademoiselle = "Mademoiselle",
}

export const CIVILITIES_ARRAY = [
  { label: CIVILITY.Monsieur, value: CIVILITY.Monsieur },
  { label: CIVILITY.Madame, value: CIVILITY.Madame },
  { label: CIVILITY.Mademoiselle, value: CIVILITY.Mademoiselle },
];

export const KEY_LANG = "lang";
export const APP_NAME = "CAEGP";
export const KEY_MAIL_USER = `KEY_MAIL_${APP_NAME}`;
export const TOKEN_USER_LOGIN = `TOKEN_${APP_NAME}`;
export const EMPTY_DATA: string = "Aucune donnée disponible.";

export const KEY_SESSION_USER = `FE8mpyKW1nc0LzrSQl+tUfa3PpVngdpgGrFnfP//i9M=_${APP_NAME}`;
export const KEY_SECRET_ENCRYPT = `N+MSvxb2s2viwh4oTgvP/VirjaWF2ityJm3XqJrxs8E=_${APP_NAME}`;
export const KEY_SECRET_ENCRYPT_DATE_LOGIN = `3628290e-247c-42b9-8019-1f5c885e28cf_${APP_NAME}`;

//  "jeu. 20 déc. 12, 03:00:00"
export const FORMATE_DATE_SHORT_WITH_TIME: Intl.DateTimeFormatOptions = {
  weekday: "short",
  day: "numeric",
  month: "short",
  year: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

// "jeu. 20 déc. 12"
export const FORMATE_DATE_SHORT_WITHOUT_TIME: Intl.DateTimeFormatOptions = {
  weekday: "short",
  day: "numeric",
  month: "short",
  year: "2-digit",
};

// "20 déc. 12,  03:00 "
export const FORMATE_DATE_SHORT_TIME_SHORT: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "short",
  year: "2-digit",
  hour: "numeric",
  minute: "numeric",
};

// "jeudi 20 décembre 2012
export const FORMATE_DATE_LONG_WITHOUT_TIME: Intl.DateTimeFormatOptions = {
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
};

export const TYPE_PIECES_SUPPORT_ARRAY = [
  {
    id: 1,
    value: TYPE_PIECES_SUPPORT.CNI,
    label: "Carte national d'identité",
  },
  {
    id: 2,
    value: TYPE_PIECES_SUPPORT.AUTRE,
    label: "Autre",
  },
];

export const LANGUE_DATE = undefined;

export enum LOCAL_STORAGE_KEY {
  COOKIE_CHOICES = "ee977806d7286510dha8b9a7492ba58e2484c0ecb",
  TOKEN = "ee977806d7286510da8b9a74j92ba58e2484c0ecc",
  ADMIN_TOKEN = "ee977806d7286510dha8b9a7492ba58e2484c0ecd",
  ACCOUNT_TYPE = "ee977806d7286m510da8b9a7492ba58e2484c0ece",
}

export const STAFF_TYPES = [
  {
    value: "PAT",
    label: "PAT",
  },
  {
    value: "PRESIDENT",
    label: "Président",
  },
  {
    value: "STUDENT",
    label: "Elèves",
  },
];

export const SEXE_DATA = [
  {
    label: "Masculin",
    value: "MALE",
  },
  {
    label: "Féminin",
    value: "FEMALE",
  },

  {
    label: "Autres",
    value: "MIXED",
  },
];

export const APP_QUESTIONS = [
  "Quel est le nom de votre premier animal de compagnie ?",
  "Quel a été votre matière préférée à l’école ?",
  "Quel est le nom du livre qui vous a marqué ?",
  "Quel est le nom de votre meilleur ami ?",
  "Quel est le nom de votre premier enfant ?",
  "Quel était le titre du livre le plus marquant que vous avez étudié ?",
  "Quel est le nom de votre  plat préféré ?",
  "Quel était  le sport que vous pratiquiez  sur le campus ?",
  "Quelle était la marque de l'ordinateur portable que vous utilisiez pour vos études ?",
  "Quel est la marque de votre premier téléphone ?",
];
export const REQUEST_TREATMENT_SOURCE = [
  {
    id: "option1",
    label:
      "Je souhaite que la plainte soit traitée par la cellule de l'UFR de l'étudiant",
    selected: false,
  },
  {
    id: "option2",
    label: "Je souhaite que la plainte soit traitée par la cellule centrale",
    selected: false,
  },
];

export const INVOLVED_PEOPLE_GRADE = [
  {
    id: "option1",
    label: "Président de l’Université",
    selected: false,
    sensibilityLevel: 10,
  },
  {
    id: "option2",
    label: "Doyen de l’UFR",
    selected: false,
    sensibilityLevel: 10,
  },
  {
    id: "option3",
    label: "Directeur de département",
    selected: false,
    sensibilityLevel: 10,
  },
  {
    id: "option23",
    label: "Autres",
    selected: false,
    sensibilityLevel: null,
  },
];
export const INVOLVED_CATEGORIES = [
  { label: "Étudiant", value: "STUDENT" },
  { label: "Parent", value: "PARENT" },
  { label: "Enseignant", value: "TEACHER" },
  { label: "Personnel administratif", value: "ADMINISTRATIVE_STAFF" },
  { label: "Personnel de sécurité", value: "SECURITY_STAFF" },
  { label: "Personnel de santé", value: "HEALTH_STAFF" },
  { label: "Personnel de restauration", value: "RESTAURANT_STAFF" },
  { label: "Personnel de transport", value: "TRANSPORT_STAFF" },
  { label: "Personnel d'entretien", value: "CLEANING_STAFF" },
  { label: "Personnel de maintenance", value: "MAINTENANCE_STAFF" },
  { label: "Autre", value: "OTHER" },
];

export const APP_SOURCES = [
  {
    value: "SMS",
    label: "SMS",
  },
  {
    value: "WHATSAPP",
    label: "Whatsapp",
  },
  {
    value: "PHONE",
    label: "Appel téléphonique",
  },
  {
    value: "PHYSICAL",
    label: "Personne physique",
  },
  {
    value: "FORM_PAPER",
    label: "Fiche de dépot de plainte",
  },
];
