import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { mappingRoutes } from "src/app/core/mappingRoutes";
import { UtilsService } from "src/app/services/utils.service";

export const authGuard: CanActivateFn = (route, state) => {
  const utilsService: UtilsService = inject(UtilsService);
  const router: Router = inject(Router);
  if (utilsService.hasToken()) {
    return true;
  }
  router.navigate([mappingRoutes.login]);
  return false;
};
