import { Routes } from "@angular/router";
import { authGuard } from "./guard/auth.guard";
import { currentLoggedAdminResolver } from "../core/resolvers/current-logged-admin.resolver";
import { noAuthGuard } from "./guard/no-auth.guard";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "auth",
    canActivate: [noAuthGuard],
    loadComponent: () =>
      import("../layouts/auth-layout/auth-layout.component").then(
        (module) => module.AuthLayoutComponent
      ),
    loadChildren: () => import("./auth/auth-routes"),
  },
  {
    path: "forgot",
    loadComponent: () =>
      import(
        "../layouts/forgot-password-layout/forgot-password-layout.component"
      ).then((module) => module.ForgotPasswordLayoutComponent),
    loadChildren: () => import("./forgot-password/forgot-password-routes"),
  },
  {
    path: "administrateur/account/access/confirm/:token",
    loadComponent: () =>
      import("./auth/define-access/define-access.component").then(
        (start) => start.DefineAccessComponent
      ),
  },

  {
    path: "administration",
    canActivate: [authGuard],
    resolve: { admin: currentLoggedAdminResolver },
    loadComponent: () =>
      import("../layouts/dashboard-layout/dashboard-layout.component").then(
        (module) => module.DashboardLayoutComponent
      ),
    data: {
      breadcrumb: "Administration",
    },
    title: "CAEGP Administration",
    loadChildren: () => import("./administration/admin-routes"),
  },
];
