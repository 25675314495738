export const mappingRoutes = {
  //
  login: "/auth/sign-in",
  register: "/auth/sign-up",
  start: "/auth/sign-in",
  //
  forgotPasswodStep1: "/forgot/sending-mail",
  forgotPasswodStep2: "/forgot/check-code",
  forgotPasswodStep3: "/forgot/defined-password",

  dashboard: "/administration/home",
  myProfile: "administration/my-profile/index",

  //
  categorieFasq: {
    create: "/administration/faqs/create",
    listing: "/administration/faqs/list",
    update: "/administration/faqs/update",
  },
  questionFaq: {
    create: "/administration/question-faqs/create",
    listing: "/administration/question-faqs/list",
    update: "/administration/question-faqs/update",
    detail: "/administration/question-faqs/show",
  },

  systemGestionPlaintes: {
    details: "/administration/referential/complaint-system/details",
    create: "/administration/referential/complaint-system/create",
    listing: "/administration/referential/complaint-system/all",
    update: "/administration/referential/complaint-system/update",
  },
  levelsTraitementPlaintes: {
    details: "/administration/level-treatment/details",
    create: "/administration/level-treatment/create",
    listing: "/administration/level-treatment/all",
    update: "/administration/level-treatment/update",
  },

  proceVerbal: {
    allPv: "/administration/verbal-report/all",
    generate: "/administration/verbal-report/generate",
  },

  messageManagements: {
    reportTransmission: {
      list: "/administration/notify/report-transmission",
      create: "/administration/notify/report-transmission/create",
      details: "/administration/notify/report-transmission/details",
    },
    appelTemoins: "/administration/notify/call-for-witness",
    appelTemoinsDetails: "/administration/notify/call-for-witness/details",
    appelTemoinsCreate: "/administration/notify/call-for-witness/create",
    appelTemoinsEdit: "/administration/notify/call-for-witness/edit",

    messageAwareness: {
      listing: "/administration/notify/message-awareness",
      create: "/administration/notify/message-awareness/create",
      edit: "/administration/notify/message-awareness/edit",
      details: "/administration/notify/message-awareness/details",
    },
  },

  plaintes: {
    details: "/administration/complaints/details",
    more: "/administration/complaints/more",
    create: {
      home: "/administration/complaints/save-new",
      anonymous: "/administration/complaints/save-anonymous-complaint",
      withIdentity: "/administration/complaints/save-complaint-with-identity",
    },
    listing: "/administration/complaints/all",
    update: "/administration/complaints/update",
    addSupplement: "/administration/complaints/add-supplement",
    reclassifie: "/administration/complaints/reclassifie",
  },

  staffs: {
    details: "/administration/staffs/show",
    create: "/administration/staffs/create",
    listing: "/administration/staffs/list",
    update: "/administration/staffs/edit",
  },
  administrateurs: {
    details: "/administration/administrateurs/details",
    create: "/administration/administrateurs/create",
    listing: "/administration/administrateurs/list",
    update: "/administration/administrateurs/update",
  },
  profiles: {
    details: "/administration/profile/details",
    create: "/administration/profile/create",
    listing: "/administration/profile/all",
    update: "/administration/profile/edit",
  },
  permissions: {
    listing: "/administration/permissions/all",
  },

  statistique: "/administration/metric/",
  notification: "/administration/notifications/listing",
  notificationSetting: "/administration/notifications/settings",
  parameter: "/administration/parameters",

  archives: {
    index: "/administration/archives/index",
  },

  referential: {
    home: "/administration/referential",

    faculties: {
      list: "/administration/referential/faculties/list",
      show: "/administration/referential/faculties/show",
    },
    ufr: {
      listing: "/administration/referential/ufr/list",
    },
    institutions: {
      listing: "/administration/referential/institutions/list",
    },
    complaintType: {
      listing: "/administration/referential/types-complaint/list",
    },

    sensibilityPlaintes: {
      listing: "/administration/referential/complaint-sensibility",
      create: "/administration/referential/complaint-sensibility/create",
      edit: "/administration/referential/complaint-sensibility/edit",
    },

    categoriesPlaintes: {
      details: "/administration/referential/categories-complaint/details",
      create: "/administration/referential/categories-complaint/create",
      listing: "/administration/referential/categories-complaint/all",
      update: "/administration/referential/categories-complaint/update",
    },
    universitiStructure: {
      details: "/administration/referential/university-structure/details",
      listing: "/administration/referential/university-structure/list",
    },

    locations: {
      cities: {
        list: "/administration/referential/cities",
        create: "/administration/referential/cities/create",
        edit: "/administration/referential/cities/edit",
        show: "/administration/referential/cities/show",
      },
      countries: {
        list: "/administration/referential/cities",
      },
    },
  },
};
