export enum API_RESPONSE_STATUS {
  SUCCESS = 200,
  SUCCESS_2000 = 2000,
  NOT_FOUND = 4000,
  INVALID_DATA = 422,
  PASSWORD_NOT_MATCH = 505,
  ALREADY_EXIST = 404,
  ACCOUNT_NOT_FOUND = 443,
}

export enum COMPLAINT_ACTION_TYPE {
  VIEW = "VIEW", // voir (ouvrir) la plainte
  COMMENT = "COMMENT", // ajouter un commentaire
  TAG = "TAG", // ajouter un tag
  JOIN_FILES = "JOIN_FILES", // joindre des fichiers
  CLOSE = "CLOSE", // fermer la plainte
  REJECT = "REJECT", // rejeter la plainte (classer sans suite)
  REOPEN = "REOPEN", // réouvrir la plainte
  CHANGE_STATUS = "CHANGE_STATUS", // changer le statut de la plainte
  CHANGE_CATEGORY = "CHANGE_CATEGORY", // changer la catégorie de la plainte
  ASSIGN_FACULTY = "ASSIGN_FACULTY", // Lié à une faculté
  ASSIGN_ORGANIZATION = "ASSIGN_ORGANIZATION", // Lié à une organisation
  EDITION = "EDITION", // Tout autre action d'édition
}
