import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { mappingRoutes } from "src/app/core/mappingRoutes";
import { UtilsService } from "src/app/services/utils.service";

export const noAuthGuard: CanActivateFn = (route, state) => {
  const utilsService: UtilsService = inject(UtilsService);
  const router: Router = inject(Router);

  if (!utilsService.hasToken()) {
    return true;
  }

  return router.parseUrl(mappingRoutes.dashboard);
};
